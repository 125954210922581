import * as Sentry from '@sentry/browser';
import { SeverityLevel } from '@sentry/browser';
import { NETWORK_ERROR_NAME } from './api/errors';

export const sentrySeverity: Record<SeverityLevel, SeverityLevel> = {
    fatal: 'fatal',
    error: 'error',
    warning: 'warning',
    log: 'log',
    info: 'info',
    debug: 'debug',
};

declare global {
    interface Window {
        // eslint-disable-next-line camelcase
        scanform_js_config: {
            SENTRY: {
                CONF: {
                    environment: string;
                    serverName: string;
                    release: string;
                    whitelistUrls: string[];
                };
                DSN: string;
            };
        };
        // eslint-disable-next-line camelcase
        user_for_sentry: {
            id: string;
            username: string;
            email: string;
        };
    }
}

const setupSentry = () => {
    const { DSN, CONF } = window.scanform_js_config.SENTRY;
    if (!DSN) {
        return;
    }

    Sentry.init({
        dsn: DSN,
        environment: CONF.environment,
        release: CONF.release,
        allowUrls: CONF.whitelistUrls,
        // Ignoring exceptions raised on browser navigation during a pending fetch
        // https://forum.sentry.io/t/typeerror-failed-to-fetch-reported-over-and-overe/8447/2
        // https://github.com/getsentry/sentry-javascript/issues/1866#issuecomment-460984819
        ignoreErrors: ['AbortError', NETWORK_ERROR_NAME, 'Failed to fetch'],
    });

    Sentry.configureScope((scope) => {
        scope.setTag('server_name', CONF.serverName);
    });

    if (Object.prototype.hasOwnProperty.call(window, 'user_for_sentry')) {
        const userForSentry = window.user_for_sentry;

        Sentry.configureScope((scope) => {
            scope.setUser(userForSentry);
        });
    }
};

export default setupSentry;
