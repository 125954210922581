/* eslint-disable max-classes-per-file */
interface ErrorOptions {
    additionalContext: Record<string, unknown>;
}

const defaultErrorOptions: ErrorOptions = { additionalContext: {} };

export const API_ERROR_NAME = 'ApiError';
export const NETWORK_ERROR_NAME = 'NetworkError';
export const RESPONSE_ERROR_NAME = 'ResponseError';

export class ApiError<T extends ErrorOptions> extends Error {
    readonly additionalContext: T['additionalContext'];

    constructor(
        message,
        { additionalContext }: ErrorOptions = defaultErrorOptions,
    ) {
        super(message);
        this.name = API_ERROR_NAME;
        this.additionalContext = additionalContext || {};
    }
}

export class NetworkError extends ApiError<never> {
    name = NETWORK_ERROR_NAME;
}

interface ResponseErrorOptions {
    additionalContext: { response: Response; responseText: string } | never;
}

export class ResponseError extends ApiError<ResponseErrorOptions> {
    name = RESPONSE_ERROR_NAME;
}
